import React from 'react'

import { Layout } from '../components'

class NotFound extends React.Component {

  title = '404'

  render() {
    return (
      <Layout page={this}>
        <div className="notfound">

          <div className="wrapper">
            <div className="mx-auto pb-30 md:w-6/8 lg:w-8/10">
              <div className="h-screen flex flex-col justify-center text-center">
                <h1 className="font-head text-24 tracking-bit pb-10 mt-40 mb-20">404 - Page not found</h1>
              </div>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

export default NotFound
